<script>
export default {
  props: [
    "id",
    "title",
    "type",
    "url",
    "content_url",
    "content",
    "is_external_url",
    "loading",
  ],
  data() {
    return {};
  },
};
</script>

<template>
  <router-link :to="{name:'post_article', params: { id: id }}"
    :href="url!='#' ? url : null"
    :target="is_external_url == 1 ? '_blank' : '_self'"
    class="card catCard"
  >
    <template
      ><div v-if="loading" class="spinner-container" role="status">
        <div class="lds-dual-ring"></div></div
    ></template>
    <h3>{{ title }}</h3>
    <div class="cardImage">
      <template v-if="type == 0">
        <img v-bind:src="content_url" class="cardVideo" />
      </template>
      <template v-else-if="type == 1">
        <video class="cardVideo" preload="auto" autoplay muted loop playsinline>
          <source v-bind:src="content_url" type="video/mp4" />
        </video>
      </template>
    </div>
    <p>{{ content }}</p>
  </router-link>
</template>